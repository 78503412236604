import { graphql } from "gatsby"
import { filter, flatMap, map } from "lodash"
import moment from "moment"
import { parse } from "query-string"
import React, { useState } from "react"
import CourseCard from "../components/courseCard"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import "../css"
import { useNiceSelectValue } from "../hooks/useNiceSelectValue"
import "../scss/index.scss"
import locales from "../translate/locales"
import makeCourseLocationsInctance from "../utils/makeCourseLocationsInctance"

export const query = graphql`
  query($locale: String!) {
    allContentfulCourses(filter: { language: { eq: $locale } }) {
      edges {
        node {
          id
          title
          language
          introductionVideoLinkYoutubeLink
          isOnline
          startDate
          endDate

          countriesAndDates {
            location
            start_date
            end_date
            contentful_id
          }

          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          price

          content {
            childMarkdownRemark {
              html
            }
          }
          totalHours
          coverImage {
            file {
              url
            }
          }

          category {
            category
            arabic
          }
        }
      }
    }
  }
`

const Courses = (props: any) => {
  const [filterText, setFilterText] = useState("")

  const locale = props.pageContext.locale
  const texts = locales[locale as "en"]

  const translate = texts.coursesPageContent
  const pageData = props.data

  const allContentfulCourses = pageData.allContentfulCourses.edges

  const selectedCategory = useNiceSelectValue("category-select", undefined)
  const selectedCountry = useNiceSelectValue("country-select", undefined)
  const selectedYear = useNiceSelectValue("year-select", undefined)
  const selectedMonth = useNiceSelectValue("month-select", undefined)

  const selectedCourseType = useNiceSelectValue(
    "course-type-select",
    undefined
  ) as "Online" | "اون لاين" | "حضوري" | "facetoface"

  const urlParams = parse(
    typeof window === "undefined" ? "" : window.location.search
  )

  const allContentfulCoursesInstance = flatMap(
    allContentfulCourses.map((_courese: any) => {
      return makeCourseLocationsInctance(_courese.node)
    })
  )

  const coursesInstanceFilterFunction = () => {
    let result = []

    result = filter(allContentfulCoursesInstance, (field: any) => {
      let condition = true
      if (field == null) {
        return false
      }

      if (selectedYear && selectedYear !== translate.year) {
        condition =
          moment(field?.startDate).toDate().getFullYear() == selectedYear
        if (!condition) {
          return false
        }

        // return condition
      }

      if (selectedMonth && selectedMonth?.trim() !== translate.month) {
        condition =
          moment(field?.startDate).toDate().getMonth() ==
          parseInt(selectedMonth)

        if (!condition) {
          return false
        }

        // return condition
      }

      if (filterText?.trim() !== "") {
        condition = field?.title
          .toLowerCase()
          .includes(filterText?.toLowerCase())

        if (!condition) {
          return false
        }
        // return condition
      }

      if (
        selectedCategory &&
        selectedCategory?.trim() !== texts.categories.category
      ) {
        const category =
          locale == "en" ? field?.category.category : field.category.arabic

        condition = category
          .toLowerCase()
          .includes(selectedCategory?.toLowerCase())

        if (!condition) {
          return false
        }
        // return condition
      }

      if (
        selectedCountry &&
        selectedCountry?.trim() !== texts.countries.country
      ) {
        condition = field?.location
          ?.trim()
          .toLowerCase()
          .includes(selectedCountry?.trim().toLowerCase())

        if (!condition) {
          return false
        }

        // return condition
      }

      if (selectedCourseType) {
        switch (selectedCourseType) {
          case "Online":
          case "اون لاين": {
            condition = field.isOnline
            break
          }
          case "facetoface":
          case "حضوري": {
            condition = !field.isOnline
            break
          }
        }
        if (!condition) {
          return false
        }
      }

      if (
        urlParams.at !== undefined &&
        ((urlParams.at as string).toLowerCase() === "online" ||
          urlParams.at === "اون لاين")
      ) {
        condition = field?.isOnline
        if (!condition) {
          return false
        }
      }

      // if (
      //   urlParams.at !== undefined &&
      //   (urlParams.at === "face-to-face" || urlParams.at === "حضوري")
      // ) {
      //   condition = !field.isOnline

      //   if (!condition) {
      //     return false
      //   }
      // }

      return true
    })

    return result
  }
  const coursesInstanceFiltered = coursesInstanceFilterFunction()

  return (
    <div>
      <Header lang={locale} />

      <div
        className="page-title bg_cover mt-80 pt-140 pb-140"
        style={{ backgroundImage: `url(/images/pexels-photo-1181304.jpeg)` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="clo-lg-8">
              <div className="page-title-item text-center">
                <h3 className="title">{translate.latestCourse}</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/course">{translate.home}</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {translate.courses}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="dream-course-area"
        style={{ marginTop: 0, paddingBottom: 0 }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="dream-course-content" style={{ borderRadius: 0 }}>
              <form action="#">
                <div
                  className="dream-course-search d-flex"
                  style={{ width: "80%", margin: "0px auto" }}
                >
                  <div className="input-box">
                    <i
                      style={{ color: "#2d589a" }}
                      className="fal fa-search"
                    ></i>
                    <input
                      type="text"
                      placeholder={texts.homePageContent.findACourseInput}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </div>
                  <div
                    id="category-select"
                    className="dream-course-category  d-lg-inline-block"
                  >
                    <select>
                      {map(texts.categories, (value) => {
                        return (
                          <option
                            value={value}
                            data-display={value}
                            selected={
                              (urlParams?.category as string)
                                ?.toLowerCase()
                                ?.trim() === value.toLowerCase()?.trim()
                            }
                          >
                            {value}
                          </option>
                        )
                      })}
                    </select>
                  </div>

                  <div
                    className="dream-course-category  d-lg-inline-block"
                    id="course-type-select"
                  >
                    <select>
                      <option
                        data-display={texts.coursesPageContent.courseType}
                      >
                        {texts.coursesPageContent.courseType}{" "}
                      </option>
                      <option
                        value="1"
                        selected={
                          urlParams?.at === "Online" ||
                          urlParams?.at === "اون لاين" ||
                          selectedCourseType === "اون لاين" ||
                          selectedCourseType === "Online"
                        }
                      >
                        {texts.coursesPageContent.online}
                      </option>
                      <option
                        value="2"
                        selected={
                          urlParams?.at === "faceToFace" ||
                          urlParams?.at === "حضوري" ||
                          selectedCourseType === "حضوري" ||
                          selectedCourseType === "facetoface"
                        }
                      >
                        {texts.coursesPageContent.faceToFace}
                      </option>
                    </select>
                  </div>

                  <div
                    className="dream-course-category  d-none d-lg-inline-block"
                    id="country-select"
                  >
                    <select>
                      {map(texts.countries, (value) => {
                        return (
                          <option
                            value={value}
                            data-display={value}
                            selected={
                              (urlParams?.country as string)
                                ?.toLowerCase()
                                ?.trim() === value.toLowerCase()?.trim()
                            }
                          >
                            {value}
                          </option>
                        )
                      })}
                    </select>
                  </div>

                  <div
                    className="dream-course-category  d-none d-lg-inline-block"
                    id="year-select"
                  >
                    <select id="yareSelect">
                      <option data-display={translate.year}>
                        {translate.year}
                      </option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                    </select>
                  </div>

                  <div
                    className="dream-course-category  d-none d-lg-inline-block"
                    id="month-select"
                  >
                    <select>
                      <option data-display={translate.month}>
                        {translate.month}
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="4">3</option>
                      <option value="5">4</option>
                      <option value="1">5</option>
                      <option value="2">6</option>
                      <option value="4">7</option>
                      <option value="5">8</option>
                      <option value="5">9</option>
                      <option value="5">10</option>
                      <option value="5">11</option>
                      <option value="5">12</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <section className="course-grid-area pt-90 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="course-grid mt-30"></div>
              <div className="row mt-10 justify-content-center">
                {coursesInstanceFiltered.map((courseData: any, key) => {
                  return <CourseCard courseData={courseData} key={key} />
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer lang={locale} />
    </div>
  )
}

export default Courses
